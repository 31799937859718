/*
Template Name: Adminto Dashboard
Author: CoderThemes
Email: coderthemes@gmail.com
File: Icons
*/

@import "icons/font-awesome/scss/font-awesome";
// @import "icons/themify-icons/themify-icons";
// @import "icons/material-design/scss/materialdesignicons";
// @import "icons/dripicons/dripicons";

