/* You can add global styles to this file, and also import other style files */
@import '../node_modules/angular-archwizard/styles/archwizard.css';
// Styling Overrides

//making all tables responsive

html {
  scroll-behavior: smooth;
  // zoom: 80%;
}

.tour-overlay {
  position: relative;
  // z-index: 9999 + 1
  z-index: 10000 !important;
}

.cropper {
  outline: rgba(255, 255, 255, 0.3) solid 100vw !important;
}

.table-hover {
  // display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

#sidebar-menu ul li .fa.fa-angle-right,
#sidebar-menu ul li .fa.fa-angle-down {
  -webkit-transition: -webkit-transform 0.15s;
  -o-transition: -o-transform 0.15s;
  transition: transform 0.15s;
  position: absolute;
  right: 5px;
  display: inline-block;
  text-rendering: auto;
  line-height: 18px;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.navbar-default,
.topbar .topbar-left {
  background-color: #253138 !important;
}

//search btn
.app-search button {
  top: 6px !important;
}

// background-pages
.account-pages {
  // background: url(src/assets/images/graphs2.png) center !important;
  background: white;

  // filter: blur(5px) grayscale(20%);
  // background: url(graphs2.png) center !important;
  -webkit-filter: blur(5px) grayscale(20%);
  filter: blur(5px) grayscale(20%);
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

//Wizard
aw-wizard#aml-wizard aw-wizard-navigation-bar {
  display: none !important;
}

//Modal for image only
modal-container .modal-dialog.showImage {
  .modal-content {
    background: transparent;
    border-color: transparent;
    padding: 0px;
    text-align: center;
  }
}

modal-container .modal-dialog.modal-theming {
  .modal-content,
  .card-box {
    background: white;
  }
  .modal-title {
    color: black;
  }
  .form-control {
    height: calc(2.53125rem + 2px);
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0px;
  }
  .card-box {
    margin-bottom: 0px;
  }
}

//Custom Overrides
.content-page {
  background: #253138;
}

.img-thumbnail {
  background-color: transparent !important;
}

div#sidebar-menu {
  margin-top: 25px;
}

.content-page .content {
  min-height: calc(100vh + 20vh);
}

//Checkbox Switch
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

input:checked + .slider {
  background-color: #33b394;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  background-color: #ccc;
  transition: 0.4s;
  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    border-radius: 5px;
    background-color: #fff;
    transition: 0.4s;
    z-index: 1;
  }
  &:after {
    content: "✕";
    position: absolute;
    right: 7px;
    top: 1px;
    font-size: x-large;
    z-index: 0;
  }
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

input:checked + .slider:after {
  content: "✓";
  left: 7px;
  color: #fff;
  right: unset;
  z-index: 0;
  font-size: x-large;
  top: 1px;
}

// div.user-box , div.topbar-left{
//   border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;
// }

//disabled btn
.btn.disabled,
.btn:disabled {
  background: #e1e1e2;
  border-color: transparent !important;
  border-bottom-color: transparent !important;
  cursor: not-allowed;
  color: rgb(41, 33, 33) !important ;
}

//datepicker styles
.md-drppicker {
  top: 50px !important;
  left: unset !important;
  right: 0 !important;
  width: 140% !important;
}

///visjs
#mynetwork .vis-network .vis-edit-mode {
  display: none !important;
}

#mynetwork {
  width: 100%;
  // min-height: 400px;
  // height: 100%;
  height: 500px;
  border: 1px solid lightgray;
  max-height: 1000px;
  background: #333333;
}

.noScroll {
  overflow: hidden;
}

// multi select override
.multiselect-dropdown .dropdown-btn {
  padding: 0px !important;
  border: 0px none !important;
}

//app-client-config
app-client-config {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  section.card-box.bg-white {
    width: 31%;
  }
}

//overrding card box shadow
.card-box {
  box-shadow: 0 13px 27px -5px rgba(37, 49, 56, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3) !important;
}

// table row hover

.table-hover > tbody > tr:hover {
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
}

//fixing date picker on userReportsPage
// #userReportsPage ngx-daterangepicker-material .md-drppicker {
//    right: auto !important;
// }

//live check wizard
//Wizard
aw-wizard#live-check-container aw-wizard-navigation-bar {
  display: none !important;
}

@media (min-width: 992px) {
  .liveCheckModal.modal-lg,
  .remoteCheckConfirmationModal.modal-lg {
    max-width: 980px;
    width: 80%;
  }

  .tabularDataModal.modal-lg {
    max-width: 90%;
    width: 90%;
  }

  .scheduleModal.modal-lg {
    width: 75%;
    max-width: 980px;
  }
}

aw-wizard#kyc-checks-flow-wizard aw-wizard-navigation-bar,
aw-wizard#checkout-form aw-wizard-navigation-bar {
  padding: 20px;
  border-bottom: 0px;
  text-align: center;
  border-top: 1px solid #4b5154;
  border-right: 1px solid #4b5154;
  background: #e1e1e136;
  cursor: pointer;
  transition: all ease 0.4s;
  position: relative;
  margin-bottom: 20px;

  &.horizontal.large-empty-symbols ul.steps-indicator li:after {
    font-size: 23px;
  }

  &.horizontal.large-empty-symbols ul.steps-indicator li.current:after,
  &.horizontal.large-empty-symbols ul.steps-indicator li.editing:after {
    color: #fff !important;
    // border: 2px solid #1abc9c !important; // OLD
    border: 2px solid #f94f06 !important;
    background-color: #f94f06 !important;
  }

  &.horizontal.large-empty-symbols ul.steps-indicator li.done:after {
    color: #fff !important;
    border: 2px solid #0a232d !important;
    background-color: #0a232d !important;
  }

  &.horizontal ul.steps-indicator li div a {
    color: white;
    line-height: 14px;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
  }
}

aw-wizard-navigation-bar.horizontal ul.steps-indicator li a .label{
  color: white;
    line-height: 14px;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    padding-top: 10px;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.current .step-indicator{
    color: #fff !important;
    border: 2px solid #f94f06 !important;
    background-color: #f94f06 !important;
    font-size: 23px!important ;
}

aw-wizard#checkout-form div.wizard-steps.horizontal {
  padding: 20px;
  border-bottom: 0px;
  border-top: 1px solid #4b5154;
  border-right: 1px solid #4b5154;
  background: #e1e1e136;
  cursor: pointer;
  transition: all ease 0.4s;
  position: relative;
  margin-bottom: 0px;
}

// CSS For Preview step
// #kyc-checks-flow-wizard > aw-wizard-navigation-bar > ul > li:last-child {
//   display: none;
// }

// #kyc-checks-flow-wizard > aw-wizard-navigation-bar > ul > li:nth-last-child(2):before {
//   display: none;
// }

aw-wizard .wizard-steps {
  display: block !important;
}

.no-shadow {
  box-shadow: none !important;
}

/*
| 
|Score Circle Plugin
|
*/

// .grade__score {
//     position: absolute;
//     top: 45%;
//     left: 50%;
//     -webkit-transform: translate(-50%, -50%);
//     transform: translate(-50%, -50%);
//     font-family: sans-serif;
//     font-weight: normal;
//     font-size: 1em;
// }

// .grade {
//     position: relative;
//     max-width: 50%;
//     font-size: 21px;
//     text-align: center;
//     margin: 0 auto;
// }

.grade {
  position: relative;
  max-width: 50%;
  font-size: 21px;
  text-align: center;
  margin: 0 auto;
  &--blue {
    color: green;
  }
  &--red {
    color: crimson;
  }

  &__score {
    position: absolute;
    top: 45%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-family: sans-serif;
    font-weight: normal;
    font-size: 1em;
  }

  &__icon {
    transform: rotate(270deg);

    &__background {
      fill: none;
      // stroke-width: 10px;
      stroke-width: 3px;
      stroke: gainsboro;
    }

    &__foreground {
      fill: none;
      stroke-width: 10px;
      stroke: currentColor;

      .animate & {
        transition: stroke-dashoffset 1s ease;
      }
    }
  }
}
// .modal .modal-dialog.remoteCheckConfirmationModal .modal-content{
//     background-color: white;
//     border-color: #e1e1e1;
//     .modal-header{
//       border-bottom: 2px solid #e1e1e1;
//       h3,h4{
//         color: black !important ;
//       }
//     }
// }

// Responsive table scroller
.table-scroller {
  overflow-x: auto;
}

.table-scroller::-webkit-scrollbar {
  width: 2px;
}

.table-scroller::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.table-scroller::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

// custom css
@keyframes placeHolderShimmer {
  0% {
    background-position: 0vw 0;
  }
  100% {
    background-position: 80vw 0;
  }
}

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  position: relative;
  background: linear-gradient(
    to right,
    #eeeeee0d 8%,
    #dddddd00 18%,
    #eeeeee0f 33%
  );
}

///payment wizard flow
aw-wizard#payment-flow-wizard {
  aw-wizard-navigation-bar.horizontal ul.steps-indicator li {
    padding: 0px;
  }

  aw-wizard-navigation-bar.horizontal.large-empty-symbols
    ul.steps-indicator
    li:after {
    position: absolute;
    top: -35px;
    left: calc(50% - 12px);
    width: 20px;
    height: 20px;
    content: "";
    text-align: center;
    vertical-align: middle;
    line-height: 46px;
    -webkit-transition: 0.25s;
    transition: 0.25s;
    border-radius: 100%;
    content: attr(step-symbol);
    border: 2px solid #e6e6e6;
  }

  aw-wizard-navigation-bar.horizontal.large-empty-symbols
    ul.steps-indicator
    li:not(:last-child):before {
    content: "";
    position: absolute;
    height: 1px;
    width: calc(100% - 50px);
    top: -25px;
    left: calc(50% + 25px);
  }

  aw-wizard-navigation-bar.horizontal.large-empty-symbols
    ul.steps-indicator
    li.done:after {
    background: #0a232d !important;
  }

  aw-wizard-navigation-bar.horizontal.large-empty-symbols
    ul.steps-indicator
    li.current:after {
    background: #f94f06 !important ;
  }
}

// ngmultiselect dropdown css
ng-multiselect-dropdown {
  display: block;
  /* border       : 1px solid red; */
  background-color: #354650;
  border: 2px solid #415663;
  border-radius: 4px;
  font-size: 14px;
  max-width: 100%;
  color: #ffffff;
  box-shadow: none;
  transition: all 300ms linear;
  padding: 6px 12px;
  .dropdown-list {
    background: #354650;
  }
}

.dropdown-list {
  background: #2f3e47 !important;
  border-color: #394c57 !important;
  border-radius: 2px !important;
  box-shadow: none !important;
  padding: 25px !important;
}

.multiselect-item-checkbox input[type="checkbox"] + div:before {
  border: 2px solid #e1e1e1 !important;
}

.multiselect-item-checkbox input[type="checkbox"] + div {
  color: white !important ;
  text-transform: capitalize;
}

.multiselect-item-checkbox input[type="checkbox"]:checked + div:before {
  background: #2f3e47 !important;
}

ng-multiselect-dropdown
  > div
  > div.dropdown-list
  > ul.item1
  > li.filter-textbox
  > input {
  background-color: #354650;
  border: 2px solid #415663;
  border-radius: 4px;
  font-size: 14px;
  max-width: 100%;
  color: #ffffff !important;
  box-shadow: none;
  transition: all 300ms linear;
  padding: 6px 12px !important;
}

.multiselect-dropdown .dropdown-btn .selected-item {
  border: 0px !important ;
  padding: 5px 10px !important;
  background: #cdcece !important;
  color: black !important;
  text-transform: capitalize;
  margin-top: 3px;
  margin-bottom: 3px;
  a {
    color: black !important;
    display: inline-block !important;
    padding: 0 6px !important;
  }
}

//scheduleTabs
#scheduleTabs {
  .nav.nav-tabs {
    .nav-link.active {
      border-bottom: 2px solid #f94f06 !important;
    }
  }
  div.tab-content {
    padding-top: 1em;
  }
  .nav-tabs > .active > a:focus,
  .nav-tabs > .active > a:hover {
    border: 2px solid transparent !important;
  }
}

//ngx bootstrap datepicker
.bs-datepicker {
  box-shadow: none !important;

  .bs-datepicker-container {
    padding: 5px !important;

    .bs-datepicker-head {
      background-color: #354650 !important;
    }
  }
}

///visjs
#mynetwork .vis-network .vis-edit-mode {
  display: none !important;
}

#mynetwork {
  width: 100%;
  // min-height: 400px;
  // height: 100%;
  height: 500px;
  border: 1px solid lightgray;
  max-height: 1000px;
  background: #333333;
}

.noScroll {
  overflow: hidden;
}
// body > bs-datepicker-container {
//   top: 15% !important;
//   left: 50% !important;
// }

#wrapper.forced.enlarged .left.side-menu {
  .user-box {
    display: none !important;
  }
  overflow-y: unset !important;
}

#wrapper .left.side-menu {
  overflow-y: scroll !important;
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
}

///Sidebar responsive
@media (max-width: 480px) {
  app-sidebar .side-menu {
    z-index: 1004 !important;
  }

  .sidebarScroller {
    overflow-y: scroll;
  }
}

.ngx-org {
  &-horizontal {
    padding: 1em 0;
  }

  &-vertical {
    padding: 0 1em;
  }

  &-border {
    border: 1px solid #808080
  }

  &-box {
    padding: 0.5em 1em;
    border-radius: .2em;
    box-shadow: 0.05em 0.05em 0.2em 0.05em #0000002b;
  }

  &-connector {
    &-horizontal {
      width: 1em;
    }

    &-vertical {
      height: 1em;
    }
  }

}


// Dynamic CSS colors for tags in adverse media

$colors: (
  "dynamic-tag-color-C27BA0": #C27BA0,
  "dynamic-tag-color-5097D9": #5097D9,
  "dynamic-tag-color-5A5D60": #5A5D60,
  "dynamic-tag-color-ED7C15": #ED7C15,
  "dynamic-tag-color-7F8C64": #7F8C64,
  "dynamic-tag-color-AB48B3": #AB48B3,
  "dynamic-tag-color-EF5DA8": #EF5DA8,
  "dynamic-tag-color-DD997B": #DD997B,
  "dynamic-tag-color-23A393": #23A393,
  "dynamic-tag-color-B4654C": #B4654C,
  "dynamic-tag-color-242424": #242424,
  "dynamic-tag-color-3835C9": #3835C9,
  "dynamic-tag-color-6349FF": #6349FF,
  "dynamic-tag-color-DCAF0E": #DCAF0E,
  "dynamic-tag-color-E05B3B": #E05B3B,
  "dynamic-tag-color-E43535": #E43535,
  "dynamic-tag-color-B27AB03D": #B27AB03D,
  "dynamic-tag-color-5097D93D": #5097D93D,
  "dynamic-tag-color-5A5D603D": #5A5D603D,
  "dynamic-tag-color-C27BA03D": #C27BA03D
);

@each $class, $value in $colors {
  .#{$class} {
    color: $value;
  }
}