/*
Template Name: Kyc Dashboard
Author: Rajit
Email: rajitg@obsessory.com
File: Core
*/

// @import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500,700);
// @import url(https://fonts.googleapis.com/css?family=Karla:400,700);
// @import url(https://fonts.googleapis.com/css?family=Rancho);

@font-face {
    font-display: swap;
    font-family: "CircularPro-Book"; //sans-serif //sentences , sub-headings
    src: url(../fonts/CircularPro-Book.otf) format("opentype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-display: swap;
    font-family: "Larsseit"; //Sans //headings
    src: url(../fonts/Larsseit.ttf) format("truetype");
    font-weight: normal;
    font-style: normal;
}

.header-title,
h1 , h2 , h3 , h4 , h5 ,
.table > thead > tr > th,
label,
li.has_sub{
	font-family: Larsseit !important;
	letter-spacing: 0.5px;
}

a,
.btn,
.table td, 
input , textarea,
ul.plan-stats li,
.card-body li,
p{
	font-family: CircularPro-Book !important;
	letter-spacing: 0.5px;
}



@import "variables";
@import "core";
@import "components";
@import "pages";
@import "menu_dark";
@import "responsive";


@import "animate";
@import "helper" ;
@import "theming" ;
